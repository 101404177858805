import React from 'react';
import { Layout } from '../layout/Layout';
import { graphql } from 'gatsby';
import Footer from '../components/Footer/Footer';
import Card from '../components/Card/Card';
import Header from '../components/Header/Header';

export default ({ data }) => {
  return (
    <div style={{backgroundColor: '#fff'}}> {/* f4f8fd */}
      <Layout>
        <Header active="home"/>

        <section className="py-5 container">
            <div className="row justify-content-md-center">
                <div className="col-md-10 mx-auto">
                    <h1 className="font-weight-light"><span role="img" aria-label="">👋</span> Hi, I'm Mehul.</h1>
                    <p className="jumbotron-text">
                      A seasoned product designer who loves finding simple solutions to complex problems. <br />
                      Currently, I am unwinding enterprise mortgage processes at Ellie Mae.
                    </p>
                    {/* <p>
                        <a href="/about" className="btn-link my-1">more about me</a>
                    </p> */}
                </div>
            </div>
        </section>

        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 gy-5 g-md-5 justify-content-md-center">
            {data.allMdx.nodes.map(
              ({ id, frontmatter, fields }) => (
                <Card 
                  title={frontmatter.title} 
                  link={fields.slug} 
                  description={frontmatter.description} 
                  subtitle={frontmatter.subtitle} 
                  passwordRequired={frontmatter.passwordRequired} 
                  coverUrl={frontmatter.coverUrl}
                  coverColor={frontmatter.coverColor}
                  isMainProject={frontmatter.isMainProject}
                  key={id}
                  index={frontmatter.index}
                />       
              )
            )}
          </div>
        </div>
        <Footer />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___index], order: ASC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          subtitle
          description
          passwordRequired
          coverUrl
          coverColor
          isMainProject
          index
        }
        fields {
          slug
        }
      }
    }
  }
`;