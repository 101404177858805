import React, { Component } from 'react';
import './Card.scss';
import { Link } from 'gatsby';

class Card extends Component {
    render() {
        let isMainProject = this.props.isMainProject ? this.props.isMainProject : false;
        if (isMainProject) {
            return (
                <div className="col-md-12 col-lg-10">
                    <div className="portfolioCard mb-0" style={{backgroundColor: this.props.coverColor}}>
                        <Link to={this.props.link} className="row g-0">
                                <div className={`col-md-6 ${this.props.index % 2 === 0 ? 'order-md-2' : ''}`}>
                                    <img src={this.props.coverUrl}  alt="..." width="100%"/>
                                </div>
                                <div className={`col-md-6 ${this.props.index % 2 === 0 ? 'order-md-1' : ''}`}>
                                    <div className="card-body">
                                        <h3 className="card-title mb-0">{this.props.title}</h3>
                                        {/* <p className="card-text"><small className="text-muted">{this.props.subtitle}</small></p> */}
                                        <p className="card-text mt-1">{this.props.description}</p>
                                        {
                                            this.props.passwordRequired && 
                                            <p className="mb-0" style={{fontSize:'0.7rem', fontWeight: '600'}}>
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-lock" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M11.5 8h-7a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-7-1a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-7zm0-3a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"/>
                                                </svg> 
                                                &nbsp;Password Protected
                                            </p>
                                        }
                                    </div>
                                </div>
                        </Link>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="col-md-6">
                    <div className="portfolioCard mb-0">
                        <div className="row g-0">
                            <Link to={this.props.link}>
                                <img src={this.props.coverUrl} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h3 className="card-title mb-0">{this.props.title}</h3>
                                    {/* <p className="card-text"><small className="text-muted">{this.props.subtitle}</small></p> */}
                                    <p className="card-text mt-1">{this.props.description}</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Card;